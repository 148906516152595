<template>
  <div class="about" v-loading="loading">
    <Navtatom :name="name" />
    <div class="taname">
      <h2>{{ name }}</h2>
    </div>
    <div class="box-abox">
      <div class="conmter">
        <div class="contop">
          <div class="input">
            <div class="id">
              <div>机柜编号:</div>
              <input type="text" v-model="inpid" placeholder="请输入机框编号" />
              <span class="delete" v-if="inpid !== ''" @click="inpid = ''"
                >×</span
              >
            </div>
            <div class="piname">
              <div>平台名称:</div>
              <input type="text" v-model="ipnname" placeholder="请输入名称" />
              <span class="delete" v-if="ipnname !== ''" @click="ipnname = ''"
                >×</span
              >
            </div>
          </div>
          <div class="bun">
            <div class="reset" @click="reset">重置</div>
            <div class="bunInquire" @click="bunInquire">查询</div>
          </div>
        </div>
      </div>
      <template>
        <div class="tabletop">
          <div class="tablename">
            <h3>{{ name }}</h3>
          </div>
          <div class="tablerigth">
            <div class="download">
              <div class="batch_redact" @click="batchRedact">批量编辑</div>
              <div class="addtemplate" @click="addtemplate">导入模板</div>
              <div class="toLead">
                <el-upload
                  class="upload-demo"
                  action="/"
                  :auto-upload="false"
                  :show-file-list="false"
                  :on-change="toLead"
                >
                  <el-button class="toLeadbun" type="text">批量导入</el-button>
                </el-upload>
              </div>
              <div class="derive" @click="derive">批量导出</div>
            </div>
            <div class="addlist">
              <template>
                <el-button
                  class="add"
                  type="text"
                  @click="dialogFacility = true"
                  >添加设备</el-button
                >
              </template>
            </div>
          </div>
        </div>
        <el-dialog
          title="批量编辑设备"
          :visible.sync="isbatch_redact"
          width="30%"
          :before-close="redactClose"
        >
          <div class="product_state" style="margin-top: 0px">
            <div class="name" style="margin-right: 20px">设备状态</div>
            <template>
              <el-select v-model="redact_state" placeholder="请选择设备状态">
                <el-option
                  v-for="(item, index) in state"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </template>
          </div>
          <div class="ota_flag">
            <div class="name" style="margin-right: 20px">远程升级标志</div>
            <template>
              <el-select v-model="redact_flag" placeholder="请选择远程升级标志">
                <el-option
                  v-for="(item, index) in flags"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </template>
          </div>
          <div class="ota_arg">
            <div class="name">远程升级参数串</div>
            <div class="inpdomain">
              <input
                type="text"
                v-model="redact_arg"
                placeholder="请输入远程升级参数串"
              />
              <span
                class="delete"
                v-if="redact_arg !== ''"
                @click="redact_arg = ''"
                >×</span
              >
            </div>
          </div>
          <div class="port">
            <div class="name">设备端口</div>
            <div class="inpdomain">
              <input
                type="text"
                v-model="redact_port"
                placeholder="请输入设备端口"
              />
              <span
                class="delete"
                v-if="redact_port !== ''"
                @click="redact_port = ''"
                >×</span
              >
            </div>
          </div>
          <div class="platformName">
            <div class="name">平台连接账号名</div>
            <div class="inpdomain">
              <input
                type="text"
                v-model="redact_platformName"
                placeholder="请输入平台连接账号名"
              />
              <span
                class="delete"
                v-if="redact_platformName !== ''"
                @click="redact_platformName = ''"
                >×</span
              >
            </div>
          </div>
          <div class="apn">
            <div class="name">通讯APN配置信息</div>
            <div class="inpdomain">
              <input
                type="text"
                v-model="redact_apn"
                placeholder="通讯APN配置信息"
              />
              <span
                class="delete"
                v-if="redact_apn !== ''"
                @click="redact_apn = ''"
                >×</span
              >
            </div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="redactCancel">取 消</el-button>
            <el-button type="primary" @click="redactCompile">确 定</el-button>
          </span>
        </el-dialog>

        <el-dialog
          title="添加设备"
          :visible.sync="dialogFacility"
          :before-close="addClose"
          width="30%"
        >
          <div class="facility">
            <div class="platform_id">
              <div :style="{ display: 'inline-block', marginRight: '49px' }">
                <span class="required">*</span>
                平台
              </div>
              <template>
                <el-select
                  v-model="platform_id"
                  filterable
                  placeholder="请选择平台"
                >
                  <el-option
                    v-for="item in lists"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </template>
            </div>
            <div class="device_id">
              <span class="required">*</span>
              设备编号
              <input
                type="text"
                v-model="device_id"
                placeholder="请输入设备编号"
              />
            </div>
            <div class="device_model">
              <span class="required">*</span>
              设备型号
              <input
                type="text"
                v-model="device_model"
                placeholder="请输入设备型号"
              />
            </div>
            <div class="slot_num">
              <span class="required">*</span>
              插槽数量
              <input
                type="text"
                v-model="slot_num"
                placeholder="请输入插槽数量"
              />
            </div>
            <div class="addproduct_key">
              <span class="required">*</span>
              产品密钥
              <input
                type="text"
                v-model="addproduct_key"
                placeholder="请输入产品密钥"
              />
            </div>
            <div class="adddevice_secret">
              <span class="required">*</span>
              设备密钥
              <input
                type="text"
                v-model="adddevice_secret"
                placeholder="请输入设备密钥"
              />
            </div>
            <div class="addstate">
              <div :style="{ display: 'inline-block', marginRight: '20px' }">
                <span class="required">*</span>
                设备状态
              </div>
              <template>
                <el-select
                  v-model="addstate"
                  filterable
                  placeholder="请选择设备状态"
                >
                  <el-option
                    v-for="(item, index) in state"
                    :key="index"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </template>
            </div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button
              @click="
                dialogFacility = false;
                  platform_id = '';
                  device_id = '';
                  device_model = '';
                  slot_num = '';
                  addproduct_key = '';
                  adddevice_secret = '';
                  addstate = '';
              "
              >取 消</el-button
            >
            <el-button type="primary" @click="addFacility">确 定</el-button>
          </span>
        </el-dialog>
        <el-table
          :data="list"
          style="width: 100%"
          :height="fullHeight"
          @selection-change="selectionChange"
          :style="{ margin: '0 auto', fontSize: '14px' }"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="platform.name" label="平台名称" width="90">
          </el-table-column>
          <el-table-column fixed prop="device_id" label="机柜编号" width="150">
          </el-table-column>
          <el-table-column prop="device_secret" label="设备密钥" width="100">
          </el-table-column>
          <el-table-column prop="device_model" label="产品型号" width="100">
          </el-table-column>
          <el-table-column prop="product_key" label="平台密钥" width="150">
          </el-table-column>
          <el-table-column prop="device_ccid" label="ICCID号" width="180">
          </el-table-column>
          <el-table-column prop="device_version" label="固件版本号" width="180">
          </el-table-column>
          <el-table-column prop="device_imei" label="IMEI串号" width="180">
          </el-table-column>
          <el-table-column
            prop="heartbeat_time"
            label="最后上报时间"
            width="180"
          >
          </el-table-column>
          <el-table-column prop="created_at" label="创建日期" width="150">
          </el-table-column>
          <el-table-column label="设备状态" width="120">
            <template slot-scope="scope">
              <el-tag size="medium">{{
                scope.row.status === 0 ? "不可用" : "可用"
              }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button size="mini" @click="handleEdit(scope.row)"
                >编辑</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </template>
      <el-dialog
        title="编辑设备"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose"
      >
        <div class="device_secret">
          <div class="name">
            <span class="required">*</span>
            设备密钥
          </div>
          <div class="inpsecret">
            <input
              type="text"
              ref="inpsecret"
              v-model="valsecret"
              placeholder="请输入设备密钥"
            />
            <span class="delete" v-if="valsecret !== ''" @click="valsecret = ''"
              >×</span
            >
          </div>
          <span class="isinput" v-if="issecret">请输入设备密钥</span>
        </div>
        <div class="product_key">
          <div class="name">
            <span class="required">*</span>
            平台密钥
          </div>
          <div class="inpkey">
            <input
              type="text"
              ref="inpkey"
              v-model="valkey"
              placeholder="请输入平台密钥"
            />
            <span class="delete" v-if="valkey !== ''" @click="valkey = ''"
              >×</span
            >
          </div>
          <span class="isinput" v-if="iskey">请输入平台密钥</span>
        </div>
        <div class="product_key">
          <div class="name">
            <span class="required">*</span>
            机柜编号
          </div>
          <div class="inpkey">
            <input
              type="text"
              ref="inpid"
              v-model="valid"
              placeholder="请输入机柜编号"
            />
            <span class="delete" v-if="valid !== ''" @click="valid = ''"
              >×</span
            >
          </div>
          <span class="isinput" v-if="isid">请输入机柜编号</span>
        </div>
        <div class="port">
          <div class="name">设备端口</div>
          <div class="inpdomain">
            <input type="text" v-model="port" placeholder="请输入设备端口" />
            <span class="delete" v-if="port !== ''" @click="port = ''">×</span>
          </div>
        </div>
        <div class="platformName">
          <div class="name">平台连接账号名</div>
          <div class="inpdomain">
            <input
              type="text"
              v-model="platformName"
              placeholder="请输入平台连接账号名"
            />
            <span
              class="delete"
              v-if="platformName !== ''"
              @click="platformName = ''"
              >×</span
            >
          </div>
        </div>
        <div class="apn">
          <div class="name">通讯APN配置信息</div>
          <div class="inpdomain">
            <input type="text" v-model="apn" placeholder="通讯APN配置信息" />
            <span class="delete" v-if="apn !== ''" @click="apn = ''">×</span>
          </div>
        </div>
        <div class="ota_arg">
          <div class="name">远程升级参数串</div>
          <div class="inpdomain">
            <input
              type="text"
              v-model="arg"
              placeholder="请输入远程升级参数串"
            />
            <span class="delete" v-if="arg !== ''" @click="arg = ''">×</span>
          </div>
        </div>
        <div class="ota_flag">
          <div class="name" style="margin-right: 20px">远程升级标志</div>
          <template>
            <el-select v-model="flag" placeholder="请选择远程升级标志">
              <el-option
                v-for="(item, index) in flags"
                :key="index"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </template>
        </div>
        <div class="product_state">
          <div class="name" style="margin-right: 20px">设备状态</div>
          <template>
            <el-select v-model="valstate" placeholder="请选择设备状态">
              <el-option
                v-for="(item, index) in state"
                :key="index"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </template>
        </div>
        <div class="Configure_flags">
          <div class="name" style="margin-right: 20px">配置标志</div>
          <template>
            <el-select v-model="valconfigure" placeholder="请选择配置标志">
              <el-option
                v-for="(item, index) in configure"
                :key="index"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </template>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancel">取 消</el-button>
          <el-button type="primary" @click="dialogcompile">确 定</el-button>
        </span>
      </el-dialog>
      <template>
        <div class="block">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[20, 40, 60, 80]"
            :page-size="100"
            layout="total, sizes, prev, pager, next, jumper"
            :total="numsum"
          >
          </el-pagination>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import Navtatom from "@/components/Navtatom";
import request from "@/plugins/request";

export default {
  name: "About",
  components: { Navtatom },
  props: {},
  data() {
    return {
      name: "设备列表",
      list: [],
      lists: [],
      state: ["可用", "不可用"],
      flags: ["需要升级", "禁止升级"],
      configure: ["配置信息有效","配置信息无效"],
      inpid: "",
      ipnname: "",
      page: 1,
      limit: 20,
      currentPage: 1,
      numsum: 0,
      isbatch_redact: false,
      dialogVisible: false,
      dialogFacility: false,
      valsecret: "", // 设备密钥
      valkey: "", // 平台密钥
      valid: "",
      adddevice_secret: "",
      addproduct_key: "",
      addstate: "",
      slot_num: "",
      device_model: "",
      device_id: "",
      platform_id: "",
      valstate: "",
      flag: "",
      valconfigure: "",
      arg: "",
      port: "",
      platformName: "",
      apn: "",
      redact_state: "",
      redact_flag: "",
      redact_arg: "",
      redact_port: "",
      redact_platformName: "",
      redact_apn: "",
      redactArr: [],
      fullHeight: document.documentElement.clientHeight - 300,
      issecret: false, //是否输入设备密钥
      iskey: false, //是否输入平台密钥
      isid: false,
      itemrow: {}, //存储要编辑的数据
      timer: false,
      loading: false,
    };
  },
  created() {
    this.getPlatformList();
    this.getListSectionForBatch();
  },
  methods: {
    getPlatformList() {
      this.loading = true;
      return request({
        url: "admin/device/list",
        method: "post",
        data: {
          token: this.$store.state.token,
          device_id: this.inpid,
          platform_name: this.ipnname,
          limit: this.limit,
          page: this.page,
        },
      }).then((res) => {
        this.loading = false;
        this.list = res.data.list;
        this.numsum = res.data.count;
        // console.log(res);
      }).catch(err => {
        this.loading = false;
      })
    },
    getListSectionForBatch() {
      return request({
        url: "admin/platform/getListSectionForBatch",
        method: "post",
        data: {
          token: this.$store.state.token,
        },
      }).then((res) => {
        this.lists = res.data.list;
        // console.log(res);
      });
    },
    //选中后触发
    selectionChange(selection) {
      this.redactArr = selection;
      // console.log(selection);
    },
    //点击批量编辑按钮触发
    batchRedact() {
      if (this.redactArr.length > 0) {
        this.isbatch_redact = true;
      } else {
        this.$message("请选择你要编辑的设备");
      }
      // console.log("批量编辑按钮");
    },
    //点击批量编辑确定按钮触发
    redactCompile() {
      this.isbatch_redact = false;
      let ids = "";
      this.redactArr.forEach((item, index) => {
        if (index == 0) {
          ids = `${item.id}`;
        } else {
          ids += `,${item.id}`;
        }
      });
      return request({
        url: "admin/device/batchEditInfo",
        method: "post",
        data: {
          token: this.$store.state.token,
          id: ids,
          status:
            this.redact_state == "可用" ? 1 : this.redact_state !== "" ? 0 : "",
          ota_flag:
            this.redact_flag == "需要升级"
              ? 1
              : this.redact_flag !== ""
              ? 0
              : "",
          ota_arg: this.redact_arg,
          port: this.redact_port,
          user_name: this.redact_platformName,
          apn: this.redact_apn,
        },
      }).then((res) => {
        this.getPlatformList();
        this.redact_state = "";
        this.redact_flag = "";
        this.redact_arg = "";
        this.redact_port = "";
        this.redact_platformName = "";
        this.redact_apn = "";
        this.$message(res.data);
        // console.log(res);
      });
      // console.log("批量编辑确定");
    },
    //点击批量编辑取消按钮触发
    redactCancel() {
      this.isbatch_redact = false;
      this.redact_state = "";
      this.redact_flag = "";
      this.redact_arg = "";
      this.redact_port = "";
      this.redact_platformName = "";
      this.redact_apn = "";
    },
    //点击x触发确定和取消
    redactClose(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          done();
          this.redact_state = "";
          this.redact_flag = "";
          this.redact_arg = "";
          this.redact_port = "";
          this.redact_platformName = "";
          this.redact_apn = "";
        })
        .catch(() => {});
    },

    bunInquire() {
      this.getPlatformList();
    },
    handleSizeChange(val) {
      this.limit = val;
      return request({
        url: "admin/device/list",
        method: "post",
        data: {
          token: this.$store.state.token,
          limit: this.limit,
        },
      }).then((res) => {
        this.list = res.data.list;
        this.numsum = res.data.count;
        // console.log(res);
      });
    },
    handleCurrentChange(val) {
      this.page = val;
      return request({
        url: "admin/device/list",
        method: "post",
        data: {
          token: this.$store.state.token,
          page: this.page,
        },
      }).then((res) => {
        this.list = res.data.list;
        this.numsum = res.data.count;
        // console.log(res);
      });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          done();
          this.issecret = false;
          this.iskey = false;
          this.isid = false;
          this.$refs.inpsecret.style.border = "1px solid rgb(207, 207, 207)";
          this.$refs.inpkey.style.border = "1px solid rgb(207, 207, 207)";
          this.$refs.inpid.style.border = "1px solid rgb(207, 207, 207)";
        })
        .catch(() => {
          this.issecret = false;
          this.iskey = false;
          this.isid = false;
        });
    },
    handleEdit(row) {
      this.dialogVisible = true;
      this.valsecret = row.device_secret;
      this.valkey = row.product_key;
      this.valid = row.device_id;
      this.valstate = row.status == 1 ? "可用" : "不可用";
      this.flag = row.ota_flag == 1 ? "需要升级" : "禁止升级";
      this.valconfigure = row.config_flag == 1 ? "配置信息有效" : "配置信息无效";
      this.arg = row.ota_arg;
      this.port = row.port;
      this.platformName = row.user_name;
      this.apn = row.apn;
      this.itemrow = row;
      // console.log(row);
    },

    reset() {
      this.ipnname = "";
      this.inpid = "";
      this.getPlatformList();
    },
    dialogcompile() {
      if (this.valsecret !== "" && this.valkey !== "" && this.valid !== "") {
        this.dialogVisible = false;
        return request({
          url: "admin/device/edit",
          method: "post",
          data: {
            token: this.$store.state.token,
            id: this.itemrow.id,
            product_key: this.valkey,
            device_id: this.valid,
            device_secret: this.valsecret,
            status: this.valstate == "可用" ? 1 : 0,
            ota_flag: this.flag == "需要升级" ? 1 : 0,
            config_flag: this.valconfigure == "配置信息有效" ? 1 : 0,
            ota_arg: this.arg,
            port: this.port,
            user_name: this.platformName,
            apn: this.apn,
          },
        }).then((res) => {
          this.getPlatformList();
          this.valsecret = "";
          this.valkey = "";
          this.valid = "";
          this.valstate = "";
          this.valconfigure = "";
          this.flag = "";
          this.arg = "";
          this.port = "";
          this.platformName = "";
          this.apn = "";
          this.$refs.inpkey.style.border = "1px solid rgb(207, 207, 207)";
          this.$refs.inpsecret.style.border = "1px solid rgb(207, 207, 207)";
          this.$refs.inpid.style.border = "1px solid rgb(207, 207, 207)";
          this.$message("编辑" + res.msg);
        });
      } else if (this.valsecret === "") {
        this.issecret = true;
        this.$refs.inpsecret.style.border = "1px solid red";
      } else if (this.valkey === "") {
        this.iskey = true;
        this.$refs.inpkey.style.border = "1px solid red";
      }else if(this.valid === ""){
        this.isid = true;
        this.$refs.inpid.style.border = "1px solid red";
      }else if(this.isid == true || this.issecret == true || this.iskey == true){
          this.$refs.inpkey.style.border = "1px solid rgb(207, 207, 207)";
          this.$refs.inpsecret.style.border = "1px solid rgb(207, 207, 207)";
          this.$refs.inpid.style.border = "1px solid rgb(207, 207, 207)";
      }
    },
    cancel() {
      this.dialogVisible = false;
      this.valsecret = "";
      this.valkey = "";
      this.valid = "";
      this.valstate = ""; 
      this.issecret = false;
      this.iskey = false;
      this.isid = false;
      this.$refs.inpsecret.style.border = "1px solid rgb(207, 207, 207)";
      this.$refs.inpkey.style.border = "1px solid rgb(207, 207, 207)";
      this.$refs.inpid.style.border = "1px solid rgb(207, 207, 207)";
    },
    addFacility() {
      this.dialogFacility = false;
      return request({
        url: "admin/device/add",
        method: "post",
        data: {
          token: this.$store.state.token,
          platform_id: this.platform_id,
          device_id: this.device_id,
          device_model: this.device_model,
          slot_num: this.slot_num,
          product_key: this.addproduct_key,
          device_secret: this.adddevice_secret,
          status: this.addstate == "可用" ? 1 : 0,
        },
      }).then((res) => {
        this.getPlatformList();
        this.platform_id = "";
        this.device_id = "";
        this.device_model = "";
        this.slot_num = "";
        this.addproduct_key = "";
        this.adddevice_secret = "";
        this.addstate = "";
        this.$message("添加" + res.msg);
      });
    },
    addClose(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          done();
          this.platform_id = "";
          this.device_id = "";
          this.device_model = "";
          this.slot_num = "";
          this.addproduct_key = "";
          this.adddevice_secret = "";
          this.addstate = "";
        })
        .catch(() => {});
    },
    toLead(file) {
      let param = new FormData();
      if (file?.raw) {
        param.append("file", file.raw, file.name);
      }
      param.append("token", this.$store.state.token);
      request({
        url: "admin/device/importList",
        method: "post",
        data: param,
      }).then((res) => {
        this.getPlatformList();
        this.$message(res.msg);
      });
      return false;
    },
    derive() {
      return request({
        url: "admin/device/exportList",
        method: "post",
        data: {
          token: this.$store.state.token,
          device_id: this.inpid,
          platform_name: this.ipnname,
          limit: this.limit,
          page: this.page,
        },
      }).then((res) => {
        window.open("https://" + res.data);
        // console.log(res);
      });
    },
    addtemplate() {
      window.open(
        "https://device.qugongxiang.vip/download/ATemplateOfDeviceInfoImport.xlsx?t=" +
          +new Date()
      );
    },
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight;
        that.fullHeight = window.fullHeight;
      })();
    };
  },
  watch: {
    fullHeight(val) {
      if (!this.timer) {
        this.fullHeight = val - 300;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          that.timer = false;
        }, 0);
      }
    },
    valkey() {
      this.iskey = false;
    },
    valsecret() {
      this.issecret = false;
    },
    valid(){
      this.isid = false;
    }
  },
};
</script>

<style lang="less" scoped>
.about {
  overflow: hidden;
  flex: 1;
  height: 100vh;
  background-color: rgb(244, 244, 244);

  .taname {
    height: 40px;
    line-height: 40px;
    padding: 0 30px 0 30px;
    font-weight: bold;
    font-size: 18px;
    background-color: rgb(255, 255, 255);
  }
  .box-abox {
    padding: 30px 40px 0 40px;
    .conmter {
      .contop {
        height: 60px;
        background-color: rgb(255, 255, 255);
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid rgb(224, 224, 224);
        .input {
          display: flex;
          justify-content: space-between;
          margin-left: 30px;
          height: 60px;
          font-size: 1.2em;
          .id {
            margin-right: 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 5px;
            position: relative;

            div {
              margin-right: 10px;
            }
            input {
              width: 15vw;
              height: 30px;
              outline: none;
              padding-left: 8px;
              padding-right: 30px;
              border-radius: 2px;
              border: 1px solid rgb(207, 207, 207);
              &:hover {
                border: 1px solid rgb(0, 157, 255);
              }
            }
          }
          .piname {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            div {
              margin-right: 10px;
            }
            input {
              width: 15vw;
              height: 30px;
              outline: none;
              padding-left: 8px;
              padding-right: 30px;
              border-radius: 2px;
              border: 1px solid rgb(207, 207, 207);
              &:hover {
                border: 1px solid rgb(0, 157, 255);
              }
            }
          }
        }
        .bun {
          display: flex;
          justify-content: space-between;
          .reset {
            width: 4vw;
            height: 40px;
            line-height: 40px;
            text-align: center;
            font-size: 16px;
            color: rgb(44, 44, 44);
            border-radius: 3px;
            background-color: rgb(255, 255, 255);
            margin-right: 30px;
            cursor: pointer;
            border: 1px solid rgb(223, 223, 223);
            &:hover {
              color: rgb(0, 157, 255);
              border-color: rgb(0, 157, 255);
              border-radius: 3px;
            }
          }
          .bunInquire {
            width: 4vw;
            height: 40px;
            line-height: 40px;
            text-align: center;
            font-size: 16px;
            color: white;
            border-radius: 3px;
            background-color: rgb(0, 157, 255);
            margin-right: 30px;
            cursor: pointer;
            &:hover {
              background-color: rgb(0, 94, 255);
              border-radius: 3px;
            }
          }
        }
      }
    }
    .tabletop {
      background-color: white;
      height: 60px;
      padding: 0 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      .tablename {
        width: 100px;
      }
      .tablerigth {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .addlist {
          width: 5vw;
          height: 40px;
          line-height: 40px;
          text-align: center;
          border-radius: 3px;
          background-color: rgb(0, 157, 255);
          &:hover {
            background-color: rgb(0, 94, 255);
          }
          .add {
            color: white;
          }
        }
        .download {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .batch_redact {
            background-color: rgb(0, 157, 255);
            width: 5vw;
            height: 40px;
            line-height: 40px;
            text-align: center;
            font-size: 14px;
            color: white;
            cursor: pointer;
            margin-right: 10px;
            border-radius: 3px;
            &:hover {
              background-color: rgb(0, 94, 255);
            }
          }
          .addtemplate {
            background-color: rgb(0, 157, 255);
            width: 5vw;
            height: 40px;
            line-height: 40px;
            text-align: center;
            font-size: 14px;
            color: white;
            cursor: pointer;
            margin-right: 10px;
            border-radius: 3px;
            &:hover {
              background-color: rgb(0, 94, 255);
            }
          }
          .toLead {
            margin-right: 10px;
            .toLeadbun {
              background-color: rgb(0, 157, 255);
              width: 5vw;
              height: 40px;
              text-align: center;
              color: white;
              font-size: 14px;
              border-radius: 3px;
              &:hover {
                background-color: rgb(0, 94, 255);
              }
            }
          }
          .derive {
            background-color: rgb(0, 157, 255);
            width: 5vw;
            height: 40px;
            text-align: center;
            color: white;
            font-size: 14px;
            line-height: 40px;
            border-radius: 3px;
            cursor: pointer;
            margin-right: 10px;
            &:hover {
              background-color: rgb(0, 94, 255);
            }
          }
        }
      }
    }
    .block {
      background-color: white;
    }
    .device_secret {
      font-size: 16px;
      .inpsecret {
        position: relative;
        display: inline;
        input {
          width: 250px;
          height: 40px;
          outline: none;
          padding-left: 8px;
          padding-right: 30px;
          margin-left: 20px;
          border-radius: 3px;
          border: 1px solid rgb(223, 223, 223);
          &:hover {
            border: 1px solid rgb(56, 184, 253);
          }
        }
      }
    }
    .product_key {
      font-size: 16px;
      margin-top: 20px;
      .inpkey {
        position: relative;
        display: inline;
        input {
          width: 250px;
          height: 40px;
          outline: none;
          padding-left: 8px;
          padding-right: 30px;
          margin-left: 20px;
          border-radius: 3px;
          border: 1px solid rgb(223, 223, 223);
          &:hover {
            border: 1px solid rgb(56, 184, 253);
          }
        }
      }
    }
    .product_state {
      font-size: 16px;
      margin-top: 20px;
      .inpkey {
        position: relative;
        display: inline;
        input {
          width: 250px;
          height: 40px;
          outline: none;
          padding-left: 8px;
          padding-right: 30px;
          margin-left: 20px;
          border-radius: 3px;
          border: 1px solid rgb(223, 223, 223);
          &:hover {
            border: 1px solid rgb(56, 184, 253);
          }
        }
      }
    }
    .Configure_flags{
      font-size: 16px;
      margin-top: 20px;
      .inpkey {
        position: relative;
        display: inline;
        input {
          width: 250px;
          height: 40px;
          outline: none;
          padding-left: 8px;
          padding-right: 30px;
          margin-left: 20px;
          border-radius: 3px;
          border: 1px solid rgb(223, 223, 223);
          &:hover {
            border: 1px solid rgb(56, 184, 253);
          }
        }
      }
    }
    .ota_flag {
      font-size: 16px;
      margin-top: 20px;
      .inpkey {
        position: relative;
        display: inline;
        input {
          width: 250px;
          height: 40px;
          outline: none;
          padding-left: 8px;
          padding-right: 30px;
          margin-left: 20px;
          border-radius: 3px;
          border: 1px solid rgb(223, 223, 223);
          &:hover {
            border: 1px solid rgb(56, 184, 253);
          }
        }
      }
    }
    .ota_arg {
      font-size: 16px;
      margin-top: 20px;
      .inpdomain {
        position: relative;
        display: inline;
        input {
          width: 250px;
          height: 40px;
          outline: none;
          padding-left: 8px;
          padding-right: 30px;
          margin-left: 20px;
          border-radius: 3px;
          border: 1px solid rgb(223, 223, 223);
          &:hover {
            border: 1px solid rgb(56, 184, 253);
          }
        }
      }
    }
    .port {
      font-size: 16px;
      margin-top: 20px;
      .inpdomain {
        position: relative;
        display: inline;
        input {
          width: 250px;
          height: 40px;
          outline: none;
          padding-left: 8px;
          padding-right: 30px;
          margin-left: 20px;
          border-radius: 3px;
          border: 1px solid rgb(223, 223, 223);
          &:hover {
            border: 1px solid rgb(56, 184, 253);
          }
        }
      }
    }
    .platformName {
      font-size: 16px;
      margin-top: 20px;
      .inpdomain {
        position: relative;
        display: inline;
        input {
          width: 250px;
          height: 40px;
          outline: none;
          padding-left: 8px;
          padding-right: 30px;
          margin-left: 20px;
          border-radius: 3px;
          border: 1px solid rgb(223, 223, 223);
          &:hover {
            border: 1px solid rgb(56, 184, 253);
          }
        }
      }
    }
    .apn {
      font-size: 16px;
      margin-top: 20px;
      .inpdomain {
        position: relative;
        display: inline;
        input {
          width: 250px;
          height: 40px;
          outline: none;
          padding-left: 8px;
          padding-right: 30px;
          margin-left: 20px;
          border-radius: 3px;
          border: 1px solid rgb(223, 223, 223);
          &:hover {
            border: 1px solid rgb(56, 184, 253);
          }
        }
      }
    }
    .facility {
      .platform_id {
        margin-bottom: 20px;
      }
      div {
        color: rgb(150, 150, 150);
        input {
          width: 250px;
          height: 40px;
          outline: none;
          padding-left: 8px;
          padding-right: 30px;
          color: rgb(150, 150, 150);
          border: 1px solid rgb(223, 223, 223);
          margin-bottom: 20px;
          margin-left: 20px;
          border-radius: 3px;
          &:hover {
            border: 1px solid rgb(56, 184, 253);
          }
        }
      }
    }
  }
  .delete {
    display: inline-block;
    width: 10px;
    height: 10px;
    text-align: center;
    line-height: 11px;
    transform: scale(1.05);
    background-color: rgb(211, 211, 211);
    color: rgb(244, 244, 244);
    border-radius: 50%;
    font-size: 12px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    &:hover {
      background-color: rgb(179, 179, 179);
    }
  }
  .required {
    color: red;
  }
  .isinput {
    color: red;
    font-size: 12px;
    margin-left: 5px;
  }
  .name {
    width: 25%;
    height: 30px;
    display: inline-block;
  }
}
</style>
